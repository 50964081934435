import React, { useState } from 'react';
import { searchSlotsForWithdrawal, deleteFutureSchedules, Slot } from '../services/api';
import '../style/main.css'
import { useNavigate } from 'react-router-dom';


const WithdrawalPage: React.FC = () => {
  const navigate = useNavigate();
  const [studentName, setStudentName] = useState('');
  const [slotId, setSlotId] = useState<number | undefined>(undefined); // Use undefined instead of null
  const [studentNumber, setStudentNumber] = useState('');
  const [withdrawalDate, setWithdrawalDate] = useState('');
  const [foundSlots, setFoundSlots] = useState<Slot[]>([]);

  const handleSearch = async () => {
    try {
      // Ensure slotId is passed as undefined if not set
      const data = await searchSlotsForWithdrawal({ studentName, slotId, studentNumber });
      setFoundSlots(data);
    } catch (error) {
      console.error('Failed to search slots:', error);
      alert('該当するスロットが見つかりませんでした。');
    }
  };

  const handleWithdraw = async () => {
    if (!withdrawalDate) {
      alert('退会予定日を入力してください。');
      return;
    }

    try {
      await deleteFutureSchedules(withdrawalDate, foundSlots.map(slot => slot.id));
      alert('退会処理が完了しました。');
      setFoundSlots([]);
    } catch (error) {
      console.error('Failed to process withdrawal:', error);
      alert('退会処理に失敗しました。');
    }
  };

  return (
    <div className='body'>
      <h1>退会処理</h1>
      <button 
          style={{ 
            position: 'absolute', 
            left: '10px', // 左から10pxの余白
            top: '10px'  // 上から10pxの余白
          }} 
          className='back-button' 
          onClick={() => navigate('/admin')}
        >
  戻る
</button>
      <div className='list-container'>
        <div>
        <label className='text-14'>生徒の名前:</label>
        <input className = 'input-box' type="text" value={studentName} onChange={(e) => setStudentName(e.target.value)} />
        </div>
      <div>
        <label className='text-14'>スロットID:</label>
        <input className = 'input-box'
          type="number"
          value={slotId !== undefined ? slotId : ''}
          onChange={(e) => setSlotId(e.target.value ? Number(e.target.value) : undefined)}
        />
      </div>
      <div>
        <label className='text-14'>生徒番号:</label>
        <input className = 'input-box' type="text" value={studentNumber} onChange={(e) => setStudentNumber(e.target.value)} />
      </div>
      <button className='small-button' onClick={handleSearch}>検索</button>

      {foundSlots.length > 0 && (
        <>
          <h2 className='text-14'>該当するスロット</h2>
          <ul>
            {foundSlots.map(slot => (
              <li className='list-item' key={slot.id}>
                {slot.studentName} - 授業日: {slot.lessonDay} - 授業時間: {slot.lessonTime}
              </li>
            ))}
          </ul>
          <div>
            <label className='text-14'>退会予定日:</label>
            <input className = 'input-box' type="date" value={withdrawalDate} onChange={(e) => setWithdrawalDate(e.target.value)} />
          </div>
          <button className='button-action' onClick={handleWithdraw}>退会処理を実行</button>
        </>
      )}
    </div>
    </div>
  );
};

export default WithdrawalPage;