import React, { useState } from 'react';
import { pauseStudentSlot, fetchStudentSlotDetails } from '../services/api'; // API 呼び出し
import { Link } from 'react-router-dom';
import './TeacherMainPage.css'; // Reuse the CSS file for sidebar styling

interface StudentSlotDetails {
  studentId: number;
  slotId: number;
  studentName: string;
  lessonDay: string;
  lessonTime: string;
  firstLessonDate: string;
}

const PauseStudentForm: React.FC = () => {
  const [studentId, setStudentId] = useState<number | undefined>(undefined);
  const [slotId, setSlotId] = useState<number | undefined>(undefined);
  const [pauseStartDate, setPauseStartDate] = useState<string>('');
  const [pauseEndDate, setPauseEndDate] = useState<string>('');
  const [studentSlotDetails, setStudentSlotDetails] = useState<StudentSlotDetails | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handlePause = async () => {
    try {
      await pauseStudentSlot({ studentId, slotId, pauseStartDate, pauseEndDate });
      alert('休会処理が完了しました');
      setShowConfirmationModal(false);
    } catch (error) {
      console.error('休会処理に失敗しました:', error);
      alert('休会処理に失敗しました');
    }
  };

  const fetchDetails = async () => {
    if (!studentId && !slotId) {
      alert('Please provide either a Student ID or Slot ID');
      return;
    }
    try {
      const details = await fetchStudentSlotDetails(studentId, slotId) as StudentSlotDetails;
      setStudentSlotDetails(details);
      setShowConfirmationModal(true);
    } catch (error) {
      console.error('Failed to fetch student slot details:', error);
      alert('Failed to fetch student slot details');
    }
  };

  return (
    <div className="body">
      <h1>休会設定</h1>
      <div className="list-container">
        
        <label className='text-14'>
          生徒ID:
          <input className = 'input-box' type="number" value={studentId || ''} onChange={(e) => setStudentId(Number(e.target.value))} />
        </label>
        <label className='text-14'>
          スロットID:
          <input className = 'input-box' type="number" value={slotId || ''} onChange={(e) => setSlotId(Number(e.target.value))} />
        </label>
        <label className='text-14'>
          休会開始日:
          <input className = 'input-box' type="date" value={pauseStartDate} onChange={(e) => setPauseStartDate(e.target.value)} />
        </label>
        <label className='text-14'>
          休会終了日:
          <input className = 'input-box' type="date" value={pauseEndDate} onChange={(e) => setPauseEndDate(e.target.value)} />
        </label>
        <button className ='button-action' onClick={fetchDetails}>休会設定を保存</button>

        {showConfirmationModal && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            zIndex: 1000,
          }}>
            <h3>休会の最終確認</h3>
            {studentSlotDetails && (
              <div>
                <p><strong>生徒名:</strong> {studentSlotDetails.studentName}</p>
                <p><strong>生徒ID:</strong> {studentSlotDetails.studentId}</p>
                <p><strong>スロットID:</strong> {studentSlotDetails.slotId}</p>
                <p><strong>休会開始日:</strong> {pauseStartDate}</p>
                <p><strong>休会終了日:</strong> {pauseEndDate}</p>
              </div>
            )}
            <button className = 'button-action' onClick={handlePause}>確認して休会設定</button>
            <button className = 'back-button' onClick={() => setShowConfirmationModal(false)}>キャンセル</button>
          </div>
        )}

        {showConfirmationModal && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 999,
          }} onClick={() => setShowConfirmationModal(false)}></div>
        )}
      </div>
    </div>
  );
};

export default PauseStudentForm;