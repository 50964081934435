import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchUnconfirmedSlots, confirmStudentSlotT, rejectStudentSlotT } from '../services/api';
import './TeacherMainPage.css';

interface UnconfirmedSlot {
  id: number;
  studentId: number;
  studentName: string;
  lessonDay: string;
  lessonTime: string;
  firstLessonDate: string;
}

function TeacherMainPage() {
  const [teacherName, setTeacherName] = useState<string | null>(null);
  const [unconfirmedSlots, setUnconfirmedSlots] = useState<UnconfirmedSlot[]>([]);

  useEffect(() => {
    const name = localStorage.getItem('teacherName');
    setTeacherName(name);

    const fetchSlots = async () => {
      try {
        const data = await fetchUnconfirmedSlots();
        setUnconfirmedSlots(data);
      } catch (error) {
        console.error('Failed to fetch unconfirmed slots:', error);
      }
    };
    fetchSlots();
  }, []);

  const handleConfirm = async (slotId: number) => {
    try {
      await confirmStudentSlotT(slotId);
      setUnconfirmedSlots(unconfirmedSlots.filter(slot => slot.id !== slotId));
    } catch (error) {
      console.error('Failed to confirm slot:', error);
    }
  };

  const handleReject = async (slotId: number) => {
    try {
      await rejectStudentSlotT(slotId);
      setUnconfirmedSlots(unconfirmedSlots.filter(slot => slot.id !== slotId));
    } catch (error) {
      console.error('Failed to reject slot:', error);
    }
  };

  return (
    <div className="teacher-main-page">
      <header className="teacher-header">
        <h1>講師メインページ</h1>
        {teacherName && (
          <p style={{ position: 'absolute', top: '10px', right: '10px' }}>ようこそ、{teacherName} さん</p>
        )}
      </header>

      <section className="teacher-notifications">
        <h2>お知らせ</h2>
        <ul>
          <li>新しい報酬体系が発表されました。</li>
          <li>2024年のスケジュール変更のお知らせ。</li>
        </ul>
      </section>

      <div className="teacher-sidebar-menu">
        <h2>機能一覧</h2>
        <ul>
          <li><Link to="/schedule/all">授業予定 (全体)</Link></li>
          <li><Link to="/rewards">報酬管理</Link></li>
          <li><Link to="/schedule/teacher">授業予定 (講師)</Link></li>
          <li><Link to="/replacement-classes">振替授業募集</Link></li>
          <li><Link to="/newClassRecruitment">新規授業募集</Link></li>
        </ul>
      </div>

      <section className="unconfirmed-slots">
        <h2>未承認の授業</h2>
        <ul>
          {unconfirmedSlots.map(slot => (
            <li key={slot.id}>
              {slot.studentName} - 生徒番号: {slot.studentId} - 授業日: {slot.lessonDay} - 授業時間: {slot.lessonTime} - 初回授業日: {slot.firstLessonDate}
              <button onClick={() => handleConfirm(slot.id)}>承認</button>
              <button onClick={() => handleReject(slot.id)}>拒否</button>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default TeacherMainPage;