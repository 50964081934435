import React, { useState, useEffect } from 'react';
import { fetchSchedules, updateScheduleTeacherId } from '../services/api';
import '../style/main.css';

interface ScheduleRow {
  id: number;
  originLessonDate: number;
  date: string;
  startTime: string;
  slotId: number;
  teacherId: number | null;
  entryTime: string;
  endTime: string;
  studentName: string;
  material: string;
}

const ReplacementClasses: React.FC = () => {
  const [replacementClasses, setReplacementClasses] = useState<ScheduleRow[]>([]);
  const [teacherId, setTeacherId] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedDay, setSelectedDay] = useState<string>(''); // For filtering by day of the week

  // ダミーデータの定義
  const dummyData: ScheduleRow[] = Array.from({ length: 5 }, (_, index) => ({
    id: index + 1,
    originLessonDate: Date.now(),
    date: new Date(Date.now() + index * 24 * 60 * 60 * 1000).toISOString(),
    startTime: '10:00',
    slotId: index + 1,
    teacherId: null,
    entryTime: '10:00',
    endTime: '11:00',
    studentName: `生徒 ${index + 1}`,
    material: `教材 ${index + 1}`,
  }));

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ja-JP', { day: '2-digit', month: '2-digit' });
  };

  useEffect(() => {
    const storedTeacherId = localStorage.getItem('userId');
    if (storedTeacherId) {
      setTeacherId(storedTeacherId);
    }

    const getReplacementClasses = async () => {
      try {
        const data: ScheduleRow[] = await fetchSchedules();
        const filteredClasses: ScheduleRow[] = data.filter((schedule: ScheduleRow) => schedule.teacherId === null);
        const sortedClasses: ScheduleRow[] = filteredClasses.sort((a, b) => 
          a.date.localeCompare(b.date) || a.entryTime.localeCompare(b.entryTime)
        );
        setReplacementClasses(sortedClasses);
      } catch (error) {
        console.error('Failed to fetch replacement classes:', error);
        setReplacementClasses(dummyData); // エラー時にダミーデータを設定
      }
    };

    getReplacementClasses();
  }, []);

  const handleReplacement = async (originLessonDate: number, slotId: number) => {
    if (!teacherId) {
      alert('ログインした講師のIDが見つかりません。');
      return;
    }

    const confirmed = window.confirm('この授業を担当しますか？');
    if (confirmed) {
      try {
        await updateScheduleTeacherId(originLessonDate, slotId, { teacherId: parseInt(teacherId, 10) });
        alert('授業を担当しました！');
        const updatedClasses: ScheduleRow[] = await fetchSchedules();
        const filteredClasses: ScheduleRow[] = updatedClasses.filter((schedule: ScheduleRow) => schedule.teacherId === null);
        const sortedClasses: ScheduleRow[] = filteredClasses.sort((a, b) => 
          a.date.localeCompare(b.date) || a.entryTime.localeCompare(b.entryTime)
        );
        setReplacementClasses(sortedClasses);
      } catch (error) {
        console.error('Failed to update schedule:', error);
        alert('授業の担当更新に失敗しました。');
      }
    }
  };

  const filteredReplacementClasses: ScheduleRow[] = replacementClasses.filter((schedule) => {
    const scheduleDateOnly = new Date(schedule.date).toISOString().slice(0, 10);
    const selectedDateOnly = selectedDate ? new Date(selectedDate).toISOString().slice(0, 10) : '';
    const dayMatch = selectedDay
      ? new Date(schedule.date).toLocaleDateString('ja-JP', { weekday: 'long' }) === selectedDay
      : true;
    const dateMatch = selectedDate ? scheduleDateOnly === selectedDateOnly : true;
    return dayMatch && dateMatch;
  });

  return (
    <div className='body'>
      <h1>振替授業募集</h1>
      <div className="list-container">
       
        
        <div>
          <label className="text-14">
            日付で検索:
            
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="input-box"
            />
            </label>
          
        </div>
        <div>
          <label className="text-14">
            曜日で検索:
            <select value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)} className="input-box">
              <option value="">すべて</option>
              <option value="日曜日">日曜日</option>
              <option value="月曜日">月曜日</option>
              <option value="火曜日">火曜日</option>
              <option value="水曜日">水曜日</option>
              <option value="木曜日">木曜日</option>
              <option value="金曜日">金曜日</option>
              <option value="土曜日">土曜日</option>
            </select>
          </label>
          </div>
         

        <ul className="slist">
          {filteredReplacementClasses.length > 0 ? (
            filteredReplacementClasses.map((schedule) => (
              <li key={schedule.id} className="list-item">
                <p>
                  {formatDate(schedule.date)} {schedule.entryTime}-{schedule.endTime} : {schedule.studentName} : {schedule.material}
                </p>
                <button  style={{ marginLeft: '20px' }} className="small-button" onClick={() => handleReplacement(schedule.originLessonDate, schedule.slotId)}>
                  担当する
                </button>
              </li>
            ))
          ) : (
            <p className="replacement-classes-no-classes">現在、振替授業の募集はありません。</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ReplacementClasses;