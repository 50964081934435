// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Schedule Table Container */
  /* Month Selector */
  .schedule-table-month-selector-schedule {
    display: block;
    margin: 0 auto 20px auto;
    padding: 8px;
    font-size: 1em;
    color: #5069c0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  /* Table */
  .schedule-table-schedule {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .schedule-table-schedule th,
  .schedule-table-schedule td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .schedule-table-schedule th {
    background-color: #f4f7f9;
    color: #333;
  }
  
  .schedule-table-schedule tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  /* Popup for Options */


`, "",{"version":3,"sources":["webpack://./src/style/ScheduleTable.css"],"names":[],"mappings":"AAAA,6BAA6B;EAC3B,mBAAmB;EACnB;IACE,cAAc;IACd,wBAAwB;IACxB,YAAY;IACZ,cAAc;IACd,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA,UAAU;EACV;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,0CAA0C;EAC5C;;EAEA;;IAEE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,eAAe;EACjB;;EAEA,sBAAsB","sourcesContent":["/* Schedule Table Container */\n  /* Month Selector */\n  .schedule-table-month-selector-schedule {\n    display: block;\n    margin: 0 auto 20px auto;\n    padding: 8px;\n    font-size: 1em;\n    color: #5069c0;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n  }\n  \n  /* Table */\n  .schedule-table-schedule {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 10px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .schedule-table-schedule th,\n  .schedule-table-schedule td {\n    padding: 12px;\n    border: 1px solid #ddd;\n    text-align: center;\n  }\n  \n  .schedule-table-schedule th {\n    background-color: #f4f7f9;\n    color: #333;\n  }\n  \n  .schedule-table-schedule tr:hover {\n    background-color: #f1f1f1;\n    cursor: pointer;\n  }\n  \n  /* Popup for Options */\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
