import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTeacher } from '../services/api'; // API呼び出し関数をインポート

const TeacherRegistrationForm: React.FC = () => {
  const [teacher, setTeacher] = useState({
    name: '',
    furigana: '',
    university: '',
    phoneNumber: '',
    bankAccount: '',
    experience: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // エラーメッセージのステート
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeacher({
      ...teacher,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage(null); // フォーム送信時にエラーメッセージをリセット
    try {
      const newTeacher = await createTeacher(teacher); // APIを呼び出して講師を登録
      console.log('Teacher created:', newTeacher);
      alert('講師が登録されました');
      navigate('/teacher'); // 成功時にメインページに遷移
    } catch (error: any) {
      console.error('Failed to create teacher:', error);
      setErrorMessage('講師の登録に失敗しました: ' + error.message); // エラーメッセージをセット
    }
  };

  return (
    <div>
      <h2>講師の登録フォーム</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} {/* エラーメッセージの表示 */}
      <form onSubmit={handleSubmit}>
        <div>
          <label>名前:</label>
          <input type="text" name="name" value={teacher.name} onChange={handleChange} />
        </div>
        <div>
          <label>ふりがな:</label>
          <input type="text" name="furigana" value={teacher.furigana} onChange={handleChange} />
        </div>
        <div>
          <label>大学名:</label>
          <input type="text" name="university" value={teacher.university} onChange={handleChange} />
        </div>
        <div>
          <label>電話番号:</label>
          <input type="text" name="phoneNumber" value={teacher.phoneNumber} onChange={handleChange} />
        </div>
        <div>
          <label>口座情報:</label>
          <input type="text" name="bankAccount" value={teacher.bankAccount} onChange={handleChange} />
        </div>
        <div>
          <label>受験経験:</label>
          <input type="text" name="experience" value={teacher.experience} onChange={handleChange} />
        </div>
        <button type="submit">登録</button>
      </form>
    </div>
  );
};

export default TeacherRegistrationForm;
