import React, { useState, useEffect } from 'react';
import { fetchPendingStudentSlots, confirmStudentSlot, proposeNewTime } from '../services/api'; // サービスのAPIをインポート

interface PendingSlot {
  id: number;
  studentId: number;
  studentName: string;
  lessonDay: string;
  lessonTime: string;
  teacherId: number;
  teacherName: string;
  firstLessonDate: string;
}

const AdminPage: React.FC = () => {
  const [pendingSlots, setPendingSlots] = useState<PendingSlot[]>([]);
  const [selectedSlotId, setSelectedSlotId] = useState<number | null>(null);
  const [newLessonDay, setNewLessonDay] = useState<string>('月');
  const [newLessonTime, setNewLessonTime] = useState<string>('17:00');
  const [newFirstLessonDate, setNewFirstLessonDate] = useState<string>(''); // 初回授業日

  
  useEffect(() => {
    // 承認待ちのスロットを取得
    const fetchSlots = async () => {
      try {
        const data = await fetchPendingStudentSlots(); // APIで取得
        setPendingSlots(data);
      } catch (error) {
        console.error('Failed to fetch pending slots:', error);
      }
    };
    fetchSlots();
  }, []);

  const handleConfirm = async (slotId: number) => {
    try {
      await confirmStudentSlot(slotId); // APIを使って承認処理
      alert('スロットが承認されました');
      setPendingSlots(pendingSlots.filter(slot => slot.id !== slotId)); // 承認後リストから削除
    } catch (error) {
      console.error('Failed to confirm slot:', error);
      alert('承認に失敗しました');
    }
  };

  const handleProposeNewTime = async () => {
    if (!selectedSlotId) return;

    try {
      await proposeNewTime({
        slotId: selectedSlotId,
        lessonDay: newLessonDay,
        lessonTime: newLessonTime,
        firstLessonDate: newFirstLessonDate, // 初回授業日を提案
      });
      alert('新しい時間を提案しました');
      setSelectedSlotId(null);
      setNewLessonDay('月');
      setNewLessonTime('17:00');
      setNewFirstLessonDate(''); // 日付リセット
    } catch (error) {
      console.error('Failed to propose new time:', error);
      alert('新しい時間の提案に失敗しました');
    }
  };

  return (
    <div>
      <h1>運営用ページ</h1>
      <h2>承認待ちのスロット</h2>
      <ul>
        {pendingSlots.map((slot) => (
          <li key={slot.id}>
            {slot.studentName} - 生徒番号: {slot.studentId} - 授業日: {slot.lessonDay} - 授業時間: {slot.lessonTime} - 初回授業日: {slot.firstLessonDate}
            <button onClick={() => handleConfirm(slot.id)}>承認</button>
            <button onClick={() => setSelectedSlotId(slot.id)}>別の日時を提案</button>
          </li>
        ))}
      </ul>

      {selectedSlotId && (
        <div>
          <h3>新しい日時を提案</h3>
          <label>
            授業日:
            <select value={newLessonDay} onChange={(e) => setNewLessonDay(e.target.value)}>
              <option value="日">日曜日</option>
              <option value="月">月曜日</option>
              <option value="火">火曜日</option>
              <option value="水">水曜日</option>
              <option value="木">木曜日</option>
              <option value="金">金曜日</option>
              <option value="土">土曜日</option>
            </select>
          </label>
          <label>
            授業時間:
            <input type="time" value={newLessonTime} onChange={(e) => setNewLessonTime(e.target.value)} min="17:00" max="22:00" />
          </label>
          <label>
            初回授業日:
            <input type="date" value={newFirstLessonDate} onChange={(e) => setNewFirstLessonDate(e.target.value)} />
          </label>
          <button onClick={handleProposeNewTime}>送信</button>
        </div>
      )}
    </div>
  );
};

export default AdminPage;