import axios from 'axios';

const API_URL =  'http://34.85.111.157:8080'; 


// 講師の作成
export const createTeacher = async (teacherData: any) => {
    try {
      const response = await fetch(`${API_URL}/api/teachers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(teacherData),  // teacherData を JSON 形式に変換
      });
  
      if (!response.ok) {
        // レスポンスが正常でない場合（HTTPステータスコードが200-299以外）
        const errorText = await response.text();  // エラーメッセージを取得
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
  
      const data = await response.json();  // 成功時に JSON レスポンスをパース
      return data;  // 講師データを返す
    } catch (error) {
      // エラーハンドリング
      console.error('Error creating teacher:', error);
      throw error;
    }
  };

// 全ての講師を取得
export const getAllTeachers = async () => {
  const response = await fetch(`${API_URL}/api/teachers`);
  return response.json();
};

// src/services/api.ts
export const fetchSchedules = async () => {
    try {
      const response = await fetch(`${API_URL}/api/class-schedules` ,{
        method: 'GET',
        credentials: 'include',
      });

      console.log(response);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch schedules:', error);
      throw error;
    }
  };
  
  // services/api.ts
  export const updateSchedule = async (originLessonDate: number,slotId:number, updateData: { reason: string, reportToParent: string,reportToTeacher: string,replacementDate: string,teacherId: number, }) => {
    const response = await fetch(`${API_URL}/api/update-schedule/${originLessonDate}/${slotId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),  // フロントエンドから更新データを送信
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    return await response.json();
  };
   export const updateScheduleByAdmin = async (originLessonDate: number,slotId:number, updateData: { date: string|null, startTime: string,teacherId: number|null, }) => {
    const response = await fetch(`${API_URL}/api/update-schedule-admin/${originLessonDate}/${slotId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),  // フロントエンドから更新データを送信
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    return await response.json();
  };
  
// services/api.ts
export const updateScheduleTeacherId = async (originLessonDate: number,slotId: number, updateData: { teacherId: number }) => {
  const response = await fetch(`${API_URL}/api/update-scheduleTeacherId/${originLessonDate}/${slotId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updateData),
  });

  if (!response.ok) {
    throw new Error('Failed to update schedule');
  }

  return await response.json();
};


// ログインAPI呼び出し
// src/services/api.ts

export const login = async (id: string, password: string) => {
  try {
    const response = await fetch(`${API_URL}/api/login/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // クッキーを送信する
      body: JSON.stringify({ id, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Login API error:', error);
    throw error;
  }
};

export const logout = async () => {
  const response = await fetch(`${API_URL}api/login/logout`, {
    method: 'POST',
    credentials: 'include', // クッキーを送信する
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

// services/api.ts

// 新規授業募集を取得
export const fetchAvailableStudentSlots = async () => {
  try {
    const response = await fetch(`${API_URL}/api/student-slots/available`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch available student slots:', error);
    throw error;
  }
};
export const updateScheduleTeacherIdForNewClass  = async (id: number, updateData: { teacherId: number }) => {
  const response = await fetch(`${API_URL}/api/student-slots/${id}`, {
    method: `PUT`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updateData),
  });

  if (!response.ok) {
    throw new Error('Failed to update student slot');
  }

  return await response.json();
};

// services/api.ts (assignSlot 関数の定義)
// services/api.ts にある assignSlot 関数を修正
export const assignSlot = async ({
  slotId,
  selectedTime,
  selectedDates,
  teacherId,
  lessonDay  // teacherId を受け取るように追加
}: {
  slotId: number;
  selectedTime: string;
  selectedDates: string[];
  teacherId: number;
  lessonDay:string  // teacherId の型定義を追加
}) => {
  try {
    const response = await fetch(`${API_URL}/api/student-slots/assign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        slotId,
        selectedTime,
        selectedDates,
        teacherId,
        lessonDay // teacherId をリクエストに含める
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to assign slot');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// 全スロットを取得するAPI呼び出し関数
export const fetchAllStudentSlots = async () => {
  const response = await fetch(`${API_URL}/api/student-slots/all`);
  const data = await response.json();
  return data;
};

// Define the FormData interface to describe the expected shape of the form data
interface FormData {
  firstName: string;
  lastName: string;
  furigana: string;
  birthDate: string;
  address: string;
  phoneNumber: string;
  userID: string;
  preferredSchedule: {
    firstPreferredDayOfWeek: string;
    firstPreferredTime: string;
    firstPrefferredTimeEnd: string;
    secondPreferredDayOfWeek?: string;
    secondPreferredTime?: string;
    thirdPreferredDayOfWeek?: string;
    thirdPreferredTime?: string;
  };
  howDidYouHear: string;
  participatedInLecture: boolean;
  universityPreference: string;
  wantsEntranceExamCourse: string;
}
export const saveStudentApplication = async (formData: FormData) => {
  try {
    console.log(formData);
    const response = await axios.post(`${API_URL}/api/students`, formData);
    return response.data;
    
  } catch (error) {
    console.error(`Failed to save student application:`, error);
    throw error;
  }
};

// services/api.ts
export const fetchPendingStudentSlots = async () => {
  try {
    const response = await fetch(`${API_URL}/api/student-slots/pending`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch pending slots:`, error);
    throw error;
  }
};
// services/api.ts
export const confirmStudentSlot = async (slotId: number) => {
  try {
    const response = await fetch(`${API_URL}/api/student-slots/confirm`, {
      method: `POST`,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ slotId }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to confirm student slot:', error);
    throw error;
  }
};

// services/api.ts
export const proposeNewTime = async ({
  slotId,
  lessonDay,
  lessonTime,
  firstLessonDate,
}: {
  slotId: number;
  lessonDay: string;
  lessonTime: string;
  firstLessonDate: string;
}) => {
  try {
    const response = await fetch(`${API_URL}/api/student-slots/propose`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        slotId,
        lessonDay,
        lessonTime,
        firstLessonDate
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to propose new schedule');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchUnconfirmedSlots = async () => {
  try {
    const response = await fetch(`${API_URL}/api/student-slots/unconfirmed`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch unconfirmed slots:', error);
    throw error;
  }
};

// services/api.ts

// 承認APIの呼び出し
export const confirmStudentSlotT = async (slotId: number) => {
  try {
    const response = await fetch(`${API_URL}/api/student-slots/confirmT`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ slotId }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to confirm student slot:', error);
    throw error;
  }
};

// 拒否処理を行う関数
export const rejectStudentSlotT = async (slotId: number) => {
  try {
    const response = await fetch(`${API_URL}/api/student-slots/reject/${slotId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Failed to reject slot:', error);
    throw error;
  }
};

export const generateSchedulesForNextThreeMonths = async () => {
  const response = await axios.post(`${API_URL}/api/generate-schedules`);
  return response.data;
};


// 退会処理のためのスロット検索
// Define an interface for searchSlotsForWithdrawal parameters
export interface Slot {
  id: number;
  studentName: string;
  lessonDay: string;
  lessonTime: string;
}

interface SearchSlotsParams {
  studentName?: string;
  slotId?: number;
  studentNumber?: string;
}

export const searchSlotsForWithdrawal = async (params: SearchSlotsParams): Promise<Slot[]> => {
  const response = await axios.post(`${API_URL}/api/withdrawal/search-slots`, params);
  return response.data as Slot[]; // Cast the response data to Slot[]
};
// Define an interface for deleteFutureSchedules parameters
interface DeleteFutureSchedulesParams {
  withdrawalDate: string;
  slotIds: number[];
}

// 退会処理の実行
export const deleteFutureSchedules = async (withdrawalDate: string, slotIds: number[]) => {
  const response = await axios.post(`${API_URL}/api/withdrawal/delete-future-schedules`, {
    withdrawalDate,
    slotIds,
  } as DeleteFutureSchedulesParams);
  return response.data;
};

// 休会処理API呼び出し関数
export const pauseStudentSlot = async ({
  studentId,
  slotId,
  pauseStartDate,
  pauseEndDate,
}: {
  studentId?: number;
  slotId?: number;
  pauseStartDate: string;
  pauseEndDate: string;
}) => {
  try {
    const response = await fetch(`${API_URL}/api/pause-student-slot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        studentId,
        slotId,
        pauseStartDate,
        pauseEndDate,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to pause student slot:', error);
    throw error;
  }
};

export const fetchStudentSlotDetails = async (studentId?: number, slotId?: number) => {
  try {
    const response = await axios.get(`${API_URL}/api/student-slot-details`, {
      params: { studentId, slotId },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch student slot details:', error);
    throw error;
  }
};

export const fetchPendingAdminSlots = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/pending-admin-approval`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch pending admin slots:', error);
    throw error;
  }
};

// Approve lesson slot
export const approveLesson = async (slotId: number) => {
  try {
    const response = await axios.post(`${API_URL}/api/approve-lesson`, { slotId });
    return response.data;
  } catch (error) {
    console.error('Failed to approve lesson:', error);
    throw error;
  }
};

export const fetchAvailableProblems = async (studentId: number, material: string) => {
  try {
    const response = await axios.get(`${API_URL}/api/available-problems/${studentId}/${material}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch available problems:', error);
    throw error;
  }
};

// services/api.ts
export const selectProblemForClassSchedule = async (slotId: number, originLessonDate: string, problemId: number, studentId: number) => {
  try {
    const response = await fetch(`${API_URL}/api/class-schedule/${slotId}/${originLessonDate}/select-problem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ problemId, studentId }),
    });
    
    if (!response.ok) {
      throw new Error(`Failed to select problem: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to select and record problem:', error);
    throw error;
  }
};

// 振り返り内容を送信するAPI関数
export const submitFeedback = async (slotId: number, originLessonDate: number, learning: string, studentBehavior: string, studentName: string, problem: string) => {
  try {
    const response = await fetch(`${API_URL}/api/class-schedule/${slotId}/${originLessonDate}/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ learning, studentBehavior, studentName, problem }),
    });

    if (!response.ok) {
      throw new Error(`Failed to submit feedback: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to submit feedback:', error);
    throw error;
  }
};

// 問題の詳細を取得するAPI関数
export const fetchProblemDetails = async (problemId: number) => {
  try {
    const response = await fetch(`${API_URL}/api/problems/${problemId}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch problem details: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Failed to fetch problem details:', error);
    throw error;
  }
};
