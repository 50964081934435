import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../style/main.css';
const API_URL =  'http://34.85.111.157:8080'; 

const ResetPasswordPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (password !== confirmPassword) {
      setError('パスワードが一致しません。');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });

      if (response.ok) {
        setMessage('パスワードがリセットされました。');
      } else {
        const data = await response.json();
        setError(data.message || 'パスワードリセットに失敗しました。');
      }
    } catch (err) {
      console.error('エラー:', err);
      setError('サーバーエラーが発生しました。');
    }
  };

  return (
    <div className='body'>
      <div className='list-container'>
      <h2>新しいパスワードを設定</h2>
      {message && <p>{message}</p>}
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <label className='text-14'>新しいパスワード:</label>
        <input
            className='input-box'
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label className='text-14' >パスワードの確認:</label>
        <input
          className='input-box'
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button className='small-button' type="submit">リセット</button>
      </form>
    </div>
    </div>
  );
};

export default ResetPasswordPage;