import React, { useState } from 'react';
import '../style/main.css';
const API_URL =  'http://34.85.111.157:8080'; 

const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      const response = await fetch(`${API_URL}/api/password/reset-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('リセットリンクをメールで送信しました。');
      } else {
        const data = await response.json();
        setError(data.message || 'リクエストに失敗しました。');
      }
    } catch (err) {
      console.error('エラー:', err);
      setError('サーバーエラーが発生しました。');
    }
  };

  return (
    <div className='body'>
        
    <div className='list-container' >
    <h2>パスワードリセット</h2>
      {message && <p>{message}</p>}
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <label className='text-14'>メールアドレス:</label>
        <input
          className='input-box'
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className = 'small-button' type="submit">送信</button>
      </form>
    </div>
    </div>
  );
};

export default ForgotPasswordPage;