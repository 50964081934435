import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { submitFeedback, fetchProblemDetails } from '../services/api';
import './feedback.css';

interface LocationState {
  row: {
    studentName: string;
    slotId: number;
    originLessonDate: number;
    problemId: number;
  };
}

const FeedbackReport: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { row: selectedRow } = location.state as LocationState;
  const [studentBehavior, setStudentBehavior] = useState<string>('');
  const [problemDetails, setProblemDetails] = useState<{ problem: string; learn: string} | null>(null);

  // 問題詳細を取得
  useEffect(() => {
    const getProblemDetails = async () => {
      try {
        const data = await fetchProblemDetails(selectedRow.problemId);
        setProblemDetails(data);
      } catch (error) {
        console.error('Failed to fetch problem details:', error);
      }
    };

    getProblemDetails();
  }, [selectedRow.problemId]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await submitFeedback(
        selectedRow.slotId,
        selectedRow.originLessonDate,
        problemDetails?.learn ?? '', // learnがundefinedの場合、空文字列を使用
        studentBehavior,
        selectedRow.studentName,
        problemDetails?.problem ?? '' // problemがundefinedの場合、空文字列を使用
      );

      navigate('/schedule/teacher', { state: { updatedRow: selectedRow } });
    } catch (error) {
      console.error('Failed to submit feedback:', error);
    }
  };

  return (
    <div className="feedback-container">
      <h1 className="feedback-header">振り返りレポート</h1>
      <p className="feedback-description">
        {selectedRow.studentName} さんの授業に関するレポートを記述してください。
      </p>

      {/* 問題と学習内容の表示 */}
      {problemDetails && (
        <div className="feedback-problem-details">
          <p><strong>問題内容:</strong> {problemDetails.problem}</p>
          <p><strong>今回の学び:</strong> {problemDetails.learn} </p>
        </div>
      )}

      <form className="feedback-form" onSubmit={handleSubmit}>

        <div className="feedback-field">
          <label htmlFor="studentBehavior">生徒の様子:</label>
          <textarea
            id="studentBehavior"
            value={studentBehavior}
            onChange={(e) => setStudentBehavior(e.target.value)}
            rows={5}
            placeholder="生徒の様子を記述してください"
          />
        </div>

        <button type="submit" className="feedback-submit-button">提出</button>
      </form>
      <button className="feedback-back-button" onClick={() => navigate(-1)}>
        戻る
      </button>
    </div>
  );
};

export default FeedbackReport;