import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSchedules, updateScheduleByAdmin } from '../services/api';
import '../style/main.css'
import '../style/ScheduleTable.css';


interface ScheduleRow {
  originLessonDate: number;
  date: string;
  newDate: string;
  startTime: string;
  newStartTime: string;
  entryTime: string;
  endTime: string;
  studentId: number;
  slotId: number;
  teacherId: number;
  newTeacherId: number;
  teacherName: string;
  studentName: string;
  comment: string;
  problem: string;
  material: string;
}

const ScheduleTable: React.FC = () => {
  const navigate = useNavigate();
  const [scheduleData, setScheduleData] = useState<ScheduleRow[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [selectedRow, setSelectedRow] = useState<ScheduleRow | null>(null);
  const [selectedProblem, setSelectedProblem] = useState<string | null>(null);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [showTeacherModal, setShowTeacherModal] = useState(false);
  const [showAbsentModal, setShowAbsentModal] = useState(false);
  const [replacementDate, setReplacementDate] = useState<string>('');
  const [replacementTime, setReplacementTime] = useState<string>('');
  const [replacementTeacherId, setReplacementTeacherId] = useState<number | null>(null);

  useEffect(() => {
    const getSchedules = async () => {
      try {
        const data = await fetchSchedules();
        setScheduleData(data);
      } catch (error) {
        console.error('Failed to fetch schedules:', error);
  
        // ダミーデータを設定
        const dummyData: ScheduleRow[] = [
          {
            originLessonDate: 20230101,
            date: '2023-01-01',
            newDate: '',
            startTime: '10:00',
            newStartTime: '',
            entryTime: '09:50',
            endTime: '11:00',
            studentId: 1,
            slotId: 101,
            teacherId: 1,
            newTeacherId: 2,
            teacherName: 'Teacher A',
            studentName: 'Student A',
            comment: 'Comment A',
            problem: 'Problem A',
            material: 'Material A',
          },
          {
            originLessonDate: 20230102,
            date: '2023-01-02',
            newDate: '',
            startTime: '11:00',
            newStartTime: '',
            entryTime: '10:50',
            endTime: '12:00',
            studentId: 2,
            slotId: 102,
            teacherId: 2,
            newTeacherId: 3,
            teacherName: 'Teacher B',
            studentName: 'Student B',
            comment: 'Comment B',
            problem: 'Problem B',
            material: 'Material B',
          },
          {
            originLessonDate: 20230103,
            date: '2023-01-03',
            newDate: '',
            startTime: '12:00',
            newStartTime: '',
            entryTime: '11:50',
            endTime: '13:00',
            studentId: 3,
            slotId: 103,
            teacherId: 3,
            newTeacherId: 4,
            teacherName: 'Teacher C',
            studentName: 'Student C',
            comment: 'Comment C',
            problem: 'Problem C',
            material: 'Material C',
          },
          {
            originLessonDate: 20230104,
            date: '2023-01-04',
            newDate: '',
            startTime: '13:00',
            newStartTime: '',
            entryTime: '12:50',
            endTime: '14:00',
            studentId: 4,
            slotId: 104,
            teacherId: 4,
            newTeacherId: 5,
            teacherName: 'Teacher D',
            studentName: 'Student D',
            comment: 'Comment D',
            problem: 'Problem D',
            material: 'Material D',
          },
          {
            originLessonDate: 20230105,
            date: '2023-01-05',
            newDate: '',
            startTime: '14:00',
            newStartTime: '',
            entryTime: '13:50',
            endTime: '15:00',
            studentId: 5,
            slotId: 105,
            teacherId: 5,
            newTeacherId: 6,
            teacherName: 'Teacher E',
            studentName: 'Student E',
            comment: 'Comment E',
            problem: 'Problem E',
            material: 'Material E',
          },
        ];
        setScheduleData(dummyData);
      }
    };
    getSchedules();
  }, []);

  const handleRowClick = (row: ScheduleRow) => {
    setSelectedRow(row);
  };

  const handleProblemClick = (problemText: string) => {
    setSelectedProblem(problemText);
  };

  const openStudentModal = () => {
    setShowStudentModal(true);
  };

  const openTeacherModal = () => {
    setShowTeacherModal(true);
  };

  const openAbsentModal = () => {
    setShowAbsentModal(true);
  };

  const handleStudentSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedRow) {
      try {
        await updateScheduleByAdmin(selectedRow.originLessonDate, selectedRow.slotId, {
          date: replacementDate,
          startTime: replacementTime,
          teacherId: replacementTeacherId,
        });
        alert('スケジュールが更新されました');
        closeAllModals();
        navigate('/schedule/all', { state: { updatedRow: selectedRow } });
      } catch (error) {
        console.error('スケジュールの更新に失敗しました:', error);
      }
    }
  };

  const handleTeacherSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedRow) {
      try {
        await updateScheduleByAdmin(selectedRow.originLessonDate, selectedRow.slotId, {
          date: selectedDate,
          startTime: selectedRow.startTime,
          teacherId: replacementTeacherId,
        });
        alert('スケジュールが更新されました');
        closeAllModals();
        navigate('/schedule/all', { state: { updatedRow: selectedRow } });
      } catch (error) {
        console.error('スケジュールの更新に失敗しました:', error);
      }
    }
  };

  const handleAbsentSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedRow) {
      try {
        await updateScheduleByAdmin(selectedRow.originLessonDate, selectedRow.slotId, {
          date: '2020/01/01',
          startTime: selectedRow.startTime,
          teacherId: replacementTeacherId,
        });
        alert('スケジュールが更新されました');
        closeAllModals();
        navigate('/schedule/all', { state: { updatedRow: selectedRow } });
      } catch (error) {
        console.error('スケジュールの更新に失敗しました:', error);
      }
    }
  };

  const closeAllModals = () => {
    setShowStudentModal(false);
    setShowTeacherModal(false);
    setShowAbsentModal(false);
    setSelectedRow(null);
    setSelectedProblem(null);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  return (
    <div className='body'>
      <h1 >授業予定</h1>
      <button 
          style={{ 
            position: 'absolute', 
            left: '10px', // 左から10pxの余白
            top: '10px'  // 上から10pxの余白
          }} 
          className='back-button' 
          onClick={() => navigate('/admin')}
        >
          戻る
        </button>
      <input
        type="date"
        className="input-box"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
      />
      <table className="schedule-table-schedule">
        <thead>
          <tr>
            <th>ID</th>
            <th>日付</th>
            <th>開始時間</th>
            <th>講師入室時間</th>
            <th>終了時間</th>
            <th>生徒名</th>
            <th>講師名</th>
            <th>教材</th>
            <th>コメント</th>
            <th>問題</th>
          </tr>
        </thead>
        <tbody>
          {scheduleData
            .filter((row) => row.date.slice(0, 10) === selectedDate)
            .sort((a, b) => a.startTime.localeCompare(b.startTime))
            .map((row, index) => (
              <tr key={index} onClick={() => handleRowClick(row)}>
                <td>{row.studentId}</td>
                <td>{formatDate(row.date)}</td>
                <td>{row.startTime}</td>
                <td>{row.entryTime}</td>
                <td>{row.endTime}</td>
                <td>{row.studentName}</td>
                <td>{row.teacherName}</td>
                <td>{row.material}</td>
                <td>{row.comment}</td>
                <td onClick={(e) => {
                  e.stopPropagation();
                  handleProblemClick(row.problem);
                }}>
                  {row.problem ? `${row.problem.slice(0, 10)}...` : '未選択'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Option Pop-up */}
      {selectedRow && (
        <div className={`popup show`}>
          <h3 >選択肢を選んでください:</h3>
          <button className="button-action" style={{marginTop: 10}}onClick={() => navigate('/problem-selection', { state: { row: selectedRow } })}>問題提示</button>
          <button className="button-action" style={{marginTop: 10}}onClick={openStudentModal}>生徒都合振替</button>
          <button className="button-action" style={{marginTop: 10}}onClick={openTeacherModal}>講師都合振替</button>
          <button className="button-action"style={{marginTop: 10}} onClick={openAbsentModal}>お休み</button>
          <button className="back-button"style={{marginTop: 10}} onClick={closeAllModals}>閉じる</button>
        </div>
      )}

      {/* Student Replacement Modal */}
      {showStudentModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>生徒都合振替の入力</h2>
            <form onSubmit={handleStudentSubmit}>
              <label>振替日付:</label>
              <input
                type="date"
                value={replacementDate}
                onChange={(e) => setReplacementDate(e.target.value)}
                required
              />
              <label>開始時間:</label>
              <input
                type="time"
                value={replacementTime}
                onChange={(e) => setReplacementTime(e.target.value)}
                required
              />
              <label>講師ID:</label>
              <input
                type="number"
                value={replacementTeacherId || ''}
                onChange={(e) => setReplacementTeacherId(parseInt(e.target.value))}
                required
              />
              <button type="submit">保存</button>
              <button type="button" onClick={closeAllModals}>キャンセル</button>
            </form>
          </div>
        </div>
      )}

      {/* Teacher Replacement Modal */}
      {showTeacherModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>講師都合振替の入力</h2>
            <form onSubmit={handleTeacherSubmit}>
              <label>講師ID:</label>
              <input
                type="number"
                value={replacementTeacherId || ''}
                onChange={(e) => setReplacementTeacherId(parseInt(e.target.value))}
                required
              />
              <button type="submit">保存</button>
              <button type="button" onClick={closeAllModals}>キャンセル</button>
            </form>
          </div>
        </div>
      )}

      {/* Absent Confirmation Modal */}
      {showAbsentModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>お休みの確認</h2>
            <form onSubmit={ handleAbsentSubmit}>
                {/* form fields */}
                <p>この授業をお休みにしますか？</p>
                <button type="submit">はい</button>
              </form>
              <button type="button" onClick={closeAllModals}>キャンセル</button>
            <p>この授業をお休みにしますか？</p>
          </div>
        </div>
      )}

      {/* Problem Detail Pop-up */}
      {selectedProblem && (
        <div className={`popup show`}>
          <h3 >問題の詳細</h3>
          <p>{selectedProblem}</p>
          <button className="back-button" onClick={closeAllModals}>閉じる</button>
        </div>
      )}
    </div>


  );
};

export default ScheduleTable;