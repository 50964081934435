import React, { useEffect, useState } from 'react';
import { fetchPendingAdminSlots, approveLesson } from '../services/api';

interface PendingAdminSlot {
  id: number;
  studentName: string;
  lessonDay: string;
  lessonTime: string;
}

function AdminApprovalPage() {
  const [pendingSlots, setPendingSlots] = useState<PendingAdminSlot[]>([]);

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const data = await fetchPendingAdminSlots();
        
        if (Array.isArray(data)) {
          setPendingSlots(data as PendingAdminSlot[]);
        } else {
          console.error('Unexpected data format:', data);
          setPendingSlots([]);
        }
      } catch (error) {
        console.error('Failed to fetch pending admin slots:', error);
      }
    };

    fetchSlots();
  }, []);

  const handleApprove = async (slotId: number) => {
    try {
      await approveLesson(slotId);
      setPendingSlots(pendingSlots.filter(slot => slot.id !== slotId));
      alert('Lesson approved and scheduled for 2 months');
    } catch (error) {
      console.error('Failed to approve lesson:', error);
    }
  };

  return (
    <div>
      <h2>Pending Lessons for Admin Approval</h2>
      <ul>
        {pendingSlots.length > 0 ? (
          pendingSlots.map(slot => (
            <li key={slot.id}>
              {slot.studentName} - {slot.lessonDay} - {slot.lessonTime}
              <button onClick={() => handleApprove(slot.id)}>Approve</button>
            </li>
          ))
        ) : (
          <p>No pending lessons available for approval.</p>
        )}
      </ul>
    </div>
  );
}

export default AdminApprovalPage;