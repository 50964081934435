import React from 'react';


const Dashboard: React.FC = () => {
  return (
    <div>
      {/* ヘッダー部分 */}
      <header className="dashboard-header">
        <nav>
          <ul className="nav-list">
            <li><a href="#schedule">授業予定</a></li>
            <li><a href="#new-classes">新規授業募集</a></li>
            <li><a href="#replacement">振替募集</a></li>
            <li><a href="#contact">お問い合わせ</a></li>
            <li><a href="#operator">オペレーター勤務</a></li>
          </ul>
        </nav>
      </header>

      {/* メインコンテンツ部分 */}
      <main className="dashboard-main">
        {/* 画像を中央に配置 */}
        <div className="image-container">
          <img src="/images/myimage.png" alt="センター画像" className="center-image" />
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
