import React, { useState, useEffect } from 'react';
import { fetchAvailableStudentSlots, fetchAllStudentSlots, assignSlot } from '../services/api';
import './NewClassRecruitment.css';

interface StudentSlot {
  id: number;
  studentId: number;
  timeSlotId: number;
  teacherId: number | null;
  lessonTime: string | null;
  lessonDay: string | null;
  student?: {
    firstPreferredDayOfWeek?: string | null;
    firstPreferredTime?: string | null;
    firstPreferredTimeEnd?: string | null;
    enrollmentDate?: string | null;
  };
}

type DayOfWeek = '日' | '月' | '火' | '水' | '木' | '金' | '土';

const NewClassRecruitment: React.FC = () => {
  const [availableStudentSlots, setAvailableStudentSlots] = useState<StudentSlot[]>([]);
  const [allStudentSlots, setAllStudentSlots] = useState<StudentSlot[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<StudentSlot | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [lessonDay, setLessonDay] = useState<string>('');

  useEffect(() => {
    const getAvailableStudentSlots = async () => {
      try {
        const data = await fetchAvailableStudentSlots();
        setAvailableStudentSlots(data);
      } catch (error) {
        console.error('Failed to fetch available student slots:', error);
      }
    };

    const getAllStudentSlots = async () => {
      try {
        const data = await fetchAllStudentSlots();
        setAllStudentSlots(data);
      } catch (error) {
        console.error('Failed to fetch all student slots:', error);
      }
    };

    getAvailableStudentSlots();
    getAllStudentSlots();
  }, []);

  const handleOpenModal = (slot: StudentSlot) => {
    setSelectedSlot(slot);
  };

  const handleCloseModal = () => {
    setSelectedSlot(null);
    setSelectedTime('');
    setSelectedDates([]);
    setLessonDay('');
  };

  const handleAssignSlot = async () => {
    const teacherId = localStorage.getItem('userId');
    if (!teacherId || !selectedSlot || !selectedTime || selectedDates.length === 0 || !lessonDay) {
      alert('すべての情報を入力してください');
      return;
    }

    try {
      await assignSlot({
        slotId: selectedSlot.id,
        selectedTime,
        selectedDates,
        teacherId: Number(teacherId),
        lessonDay,
      });
      alert('授業が割り当てられました');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to assign slot:', error);
      alert('授業の割り当てに失敗しました');
    }
  };

  const handleDateSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedDates(selectedOptions);
    if (selectedOptions.length > 0) {
      const selectedDate = new Date(selectedOptions[0]);
      const selectedDayOfWeek = selectedDate.getDay();
      const dayMap: Record<number, DayOfWeek> = { 0: '日', 1: '月', 2: '火', 3: '水', 4: '木', 5: '金', 6: '土' };
      setLessonDay(dayMap[selectedDayOfWeek]);
    }
  };

  const filterTimesByExistingSlots = (timeOptions: string[], preferredDayOfWeek: string) => {
    const timeCountMap: Record<string, number> = {};

    allStudentSlots.forEach((slot) => {
      if (slot.lessonDay && slot.lessonTime) {
        const formattedLessonTime = slot.lessonTime.split(':').slice(0, 2).join(':');
        if (slot.lessonDay === preferredDayOfWeek) {
          timeCountMap[formattedLessonTime] = (timeCountMap[formattedLessonTime] || 0) + 1;
        }
      }
    });

    return timeOptions.filter((time) => {
      const formattedTime = time.split(':').slice(0, 2).join(':');
      return (timeCountMap[formattedTime] || 0) < 4;
    });
  };

  return (
    <div className="new-class-container">
      <h1 className="new-class-header">新規授業募集</h1>
      <ul className="new-class-list">
        {availableStudentSlots.map((slot) => (
          <li key={slot.id} className="new-class-list-item">
             {slot.studentId}: {slot.student?.firstPreferredDayOfWeek ?? '未設定'}-{slot.student?.firstPreferredTime}-{slot.student?.firstPreferredTimeEnd}
            <button className="new-class-button" onClick={() => handleOpenModal(slot)}>
              担当する
            </button>
          </li>
        ))}
      </ul>

      {selectedSlot && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>授業の詳細を設定</h2>
            <h3>時間を選択してください（5分ごと）</h3>
            <select
              onChange={(e) => setSelectedTime(e.target.value)}
              value={selectedTime}
              className="modal-select"
            >
              {filterTimesByExistingSlots(
                generateTimeOptions(
                  selectedSlot.student?.firstPreferredTime || '09:00',
                  selectedSlot.student?.firstPreferredTimeEnd || '18:00'
                ),
                selectedSlot.student?.firstPreferredDayOfWeek || lessonDay
              ).map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>

            <h3>授業可能な日付を選択してください</h3>
            <select multiple onChange={handleDateSelection} className="modal-select">
              {generateAvailableDates(selectedSlot).map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>

            <button className="modal-button" onClick={handleAssignSlot}>
              割り当て
            </button>
            <button className="modal-button close" onClick={handleCloseModal}>
              キャンセル
            </button>
          </div>
        </div>
      )}
    </div>
  );

  function generateTimeOptions(firstPreferredTime: string, firstPreferredTimeEnd: string) {
    const times = [];
    const [startHour, startMinute] = firstPreferredTime.split(':').map(Number);
    const [endHour, endMinute] = firstPreferredTimeEnd.split(':').map(Number);
    const startTime = new Date();
    startTime.setHours(startHour, startMinute, 0, 0);
    const endTime = new Date();
    endTime.setHours(endHour, endMinute, 0, 0);
    let currentTime = new Date(startTime);
    while (currentTime <= endTime) {
      times.push(
        `${String(currentTime.getHours()).padStart(2, '0')}:${String(currentTime.getMinutes()).padStart(2, '0')}`
      );
      currentTime.setMinutes(currentTime.getMinutes() + 5);
    }
    return times;
  }

  function generateAvailableDates(slot: StudentSlot) {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 4; i++) {
      const targetDay = getNextDayOfWeek(today, slot.student?.firstPreferredDayOfWeek as DayOfWeek);
      dates.push(targetDay.toISOString().split('T')[0]);
      today.setDate(today.getDate() + 7);
    }
    return dates;
  }

  function getNextDayOfWeek(date: Date, dayOfWeek: DayOfWeek | undefined) {
    const dayMap: Record<DayOfWeek, number> = { 日: 0, 月: 1, 火: 2, 水: 3, 木: 4, 金: 5, 土: 6 };
    if (!dayOfWeek) return date;
    const targetDay = dayMap[dayOfWeek];
    const currentDay = date.getDay();
    const daysUntilNext = (targetDay + 7 - currentDay) % 7;
    return new Date(date.setDate(date.getDate() + daysUntilNext));
  }
};

export default NewClassRecruitment;