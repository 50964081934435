import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAvailableProblems, selectProblemForClassSchedule } from '../services/api';
import '../style/main.css';

interface Problem {
  id: number;
  problem: string;
}

const ProblemSelection: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { studentId, material, originLessonDate, slotId } = (location.state as any)?.row || {};

  const [problems, setProblems] = useState<Problem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProblems = async () => {
      try {
        console.log('Fetching problems with:', { studentId, material });
        const data = await fetchAvailableProblems(studentId, material) as Problem[];
        setProblems(data);
        console.log('Fetched problems:', data);
      } catch (error) {
        console.error('Failed to fetch available problems:', error);

        // ダミーデータをセット
        const dummyProblems: Problem[] = [
          { id: 1, problem: 'ダミー問題1: 文字列操作' },
          { id: 2, problem: 'ダミー問題2: 数列の合計あああああああああああああああああああああああ' },
          { id: 3, problem: 'ダミー問題3: 二次方程式' },
          { id: 4, problem: 'ダミー問題4: 幾何学問題' },
          { id: 5, problem: 'ダミー問題5: プログラム設計' },
        ];
        setProblems(dummyProblems);
      } finally {
        setLoading(false);
      }
    };

    if (studentId && material) {
      getProblems();
    } else {
      // studentId または material が不足している場合、ダミーデータを使用
      const dummyProblems: Problem[] = [
        { id: 1, problem: 'ダミー問題1: 文字列操作' },
        { id: 2, problem: 'ダミー問題2: 数列の合計' },
        { id: 3, problem: 'ダミー問題3: 二次方程式' },
        { id: 4, problem: 'ダミー問題4: 幾何学問題' },
        { id: 5, problem: 'ダミー問題5: プログラム設計' },
      ];
      setProblems(dummyProblems);
      setLoading(false);
    }
  }, [studentId, material]);

  const handleProblemSelect = async (problemId: number) => {
    try {
      console.log(`Selecting problem with ID: ${problemId}`);
      await selectProblemForClassSchedule(slotId, originLessonDate, problemId, studentId);
      navigate('/schedule/all', { state: { updatedRow: { studentId, originLessonDate, problemId } } });
    } catch (error) {
      console.error('Failed to select problem:', error);
    }
  };

  // 戻るボタンの処理
  const handleBack = () => {
    navigate('/schedule/all'); // 1ページ戻る
  };

  return (
    <div className='body'>
      <h1>問題提示</h1>
      <div className="list-container">
        <p className="text-14">提示する問題を選んでください。</p>
        <p className="text-14"><strong>生徒番号:</strong> {studentId}</p>
        
       {loading ? (
          <p className="text-14">ロード中...</p>
        ) : problems.length > 0 ? (
          <div>
            <ul className="list">
              {problems.map((problem) => (
                <li key={problem.id} className="list-item">
                    {problem.problem.slice(0, 19)}
                  <button  className = 'small-button'onClick={() => handleProblemSelect(problem.id)}>
                  選択
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p className="text-14">利用可能な問題はありません。</p>
        )}
        
        <div className="back-button-container">
          <button className="back-button" onClick={handleBack}>戻る</button> {/* 戻るボタン */}
        </div>
      </div>
    </div>
  );
};

export default ProblemSelection;