import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateSchedule } from '../services/api'; // API呼び出し関数をインポート
import './feedback.css';

interface LocationState {
  row: {
    originalLessonDate: number;
    slotId: number;
    teacherId: number;
    studentName: string;
    date: string;
    [key: string]: any;
  };
}

const ReplacementRequest: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { row: selectedRow } = location.state as LocationState;

  const [reason, setReason] = useState<string>('');
  const [reportToP, setReportToP] = useState<string>('');
  const [reportToT, setReportToT] = useState<string>('');
  const [date, setDate] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('振替理由:', reason);
    console.log('保護者へのメッセージ:', reportToP);
    console.log('代理講師へのメッセージ:', reportToT);
    console.log('代理勤務日程:', date);
    console.log('授業日程:', selectedRow.date);

    try {
      await updateSchedule(selectedRow.originalLessonDate, selectedRow.slotId, {
        reason,
        reportToParent: reportToP,
        reportToTeacher: reportToT,
        replacementDate: date,
        teacherId: selectedRow.teacherId,
      });

      navigate('/schedule/teacher', { state: { updatedRow: selectedRow } });
    } catch (error) {
      console.error('スケジュールの更新に失敗しました:', error);
    }
  };

  const formattedDate = new Date(selectedRow.date).toLocaleDateString('ja-JP', {
    day: '2-digit',
    month: '2-digit',
  });

  return (
    <div className="feedback-container">
      <h1 className="feedback-header">振替申請</h1>
      <p className="feedback-description">
        {formattedDate} - {selectedRow.studentName} さんの授業に関する振替申請を行ってください。
      </p>

      <form className="feedback-form" onSubmit={handleSubmit}>
        <div className="feedback-field">
          <label htmlFor="reason">お休みの理由:</label>
          <textarea
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            rows={5}
            placeholder="理由を記述してください"
          />
        </div>

        <div className="feedback-field">
          <label htmlFor="reportToP">保護者へのメッセージ:</label>
          <textarea
            id="reportToP"
            value={reportToP}
            onChange={(e) => setReportToP(e.target.value)}
            rows={5}
            placeholder="保護者や生徒に伝えたいメッセージを入力してください。このメッセージは保護者へ直接送信されますので、丁寧な文面でお願いします。"
          />
        </div>

        <div className="feedback-field">
          <label htmlFor="reportToT">代理講師へのメッセージ:</label>
          <textarea
            id="reportToT"
            value={reportToT}
            onChange={(e) => setReportToT(e.target.value)}
            rows={5}
            placeholder="代理講師に知ってもらいたい授業内容や、生徒の特徴について記入してください。授業の進め方や注意事項などを具体的にお書きください。"
          />
        </div>

        <div className="feedback-field">
          <label htmlFor="date">代理で勤務できる日程:</label>
          <textarea
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            rows={5}
            placeholder="代理で授業を行える日程を、YYYY/MM/DD HH:mm - HH:mm の形式で記入してください。例: 2024/10/14 19:00 - 22:00"
          />
        </div>

        <button type="submit" className="feedback-submit-button">提出</button>
      </form>
      <button className="feedback-back-button" onClick={() => navigate(-1)}>
        戻る
      </button>
    </div>
  );
};

export default ReplacementRequest;