import React from 'react';

const Rewards: React.FC = () => {
  return (
    <div>
      <h1>報酬管理</h1>
      <p>こちらで講師の報酬情報を確認できます。</p>
      <table>
        <thead>
          <tr>
            <th>月</th>
            <th>報酬額</th>
            <th>支払い状況</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>9月</td>
            <td>300,000円</td>
            <td>支払い済み</td>
          </tr>
          <tr>
            <td>8月</td>
            <td>250,000円</td>
            <td>支払い済み</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Rewards;
