import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/api';
import "../style/main.css";
import { Link } from 'react-router-dom';


interface LoginPageProps {
  setIsAuthenticated: (value: boolean) => void;
  setIsAdmin: (value: boolean) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ setIsAuthenticated, setIsAdmin }) => {
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  // 通常のログイン処理
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    
    try {
      const data = await login(id, password);
  
      if (data.teacher) {
        setIsAuthenticated(true);
        navigate('/admin');
      } else {
        setError('IDまたはパスワードが正しくありません。');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error instanceof Error ? error.message : 'ログインに失敗しました。');
    }
  };
  // 運営用ページの認証プロセス
 
      return (
        <div className="body">
           
          <div style={{marginTop: 30}}className="container">
            {/* ロゴ */}
            <div className="logo"></div>
    
            {/* エラーメッセージ */}
            {error && <p className="error-message">{error}</p>}
    
            {/* ログインフォーム */}
            <form onSubmit={handleLogin} >
              {/* ユーザーID入力 */}
              <div >
                <label htmlFor="user-id" className="text-14" >ユーザーID:</label>
                <input
                  id="user-id"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  className="input-box"
                />
              </div>
    
              {/* パスワード入力 */}
              <div >
                <label htmlFor="password" className="text-14">パスワード:</label>
                <input
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-box"
                />
                <li ><Link to="/reset-req" className="text-14">パスワードの設定</Link></li>
              </div>
    
              {/* ログインボタン */}
              <button type="submit" className="button-action">ログイン</button>
            </form>
          </div>
        </div>
      );
    };
    


export default LoginPage;