import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/menu.css';
import '../style/main.css';

import { generateSchedulesForNextThreeMonths } from '../services/api';

function TeacherMainPage() {
  const [teacherName, setTeacherName] = useState<string | null>(null);

  useEffect(() => {
    const name = localStorage.getItem('teacherName');
    setTeacherName(name);
  }, []);

  const handleGenerateSchedules = async () => {
    try {
      await generateSchedulesForNextThreeMonths();
      alert('3ヶ月分のスケジュールが生成されました');
    } catch (error) {
      console.error('Failed to generate schedules:', error);
      alert('スケジュールの生成に失敗しました');
    }
  };

  return (
      <div className="menu-body">
        
        <ul className='menu-ul'>
        <li className="menu-li"><Link to="/schedule/all">授業予定 (全体)</Link></li>
        <li className="menu-li"><Link to="/rewards">報酬管理</Link></li>
        <li className="menu-li"><Link to="/schedule/teacher">授業予定 (講師)</Link></li>
        <li className="menu-li"><Link to="/replacement-classes">振替授業募集</Link></li>
        <li className="menu-li"><Link to="/newClassRecruitment">新規授業募集</Link></li>
        <li className="menu-li"><Link to="/adminNewClass">新規授業-生徒-承認</Link></li>
        <li className="menu-li"><Link to="/adminClass">新規授業-運営-承認</Link></li>
        <li className="menu-li"><Link to="/adminWithdraw">退会処理</Link></li>
        <li className="menu-li"><Link to="/adminPause">休会処理</Link></li>
      </ul>
  <div style={{ marginTop: '20px' }}>
  <button className="button-onmenu" onClick={handleGenerateSchedules}>
    3ヶ月分のスケジュールを生成
  </button>
  </div>

      </div>
      
  );
}

export default TeacherMainPage;