import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './componets/LoginPage';  // ログインページ
import TeacherMainPage from './componets/TeacherMainPage';     // メインページ
import ScheduleAll from './componets/ScheduleAll';             // 授業予定(全体)
import Rewards from './componets/Rewards';                     // 報酬管理
import ScheduleTeacher from './componets/ScheduleTeacher';     // 授業予定(講師用)
import ReplacementClasses from './componets/ReplacementClasses'; // 振替授業募集
import ReplacementRequest from './componets/ReplacementRequest'; // 振替授業募集
import ProblemSelection from './componets/ProblemSelection';   // 問題提示
import FeedbackReport from './componets/FeedbackReport';       // 振り返りレポート
import Dashboard from './componets/Dashboard';                 // ダッシュボード
import TeacherRegistrationForm from './componets/TeacherRegistrationForm'; // 講師登録フォーム
import NewClassRecruitment from './componets/NewClassRecruitment';
import StudentApplication from './componets/StudentApplicationPage';
import AdminPage from './componets/AdminPage';
import AdminNewClass from './componets/adminNewClass';
import AdminWithdraw from './componets/WithdrawalPage';
import AdminPouse from './componets/pausePage';
import AdminNewClassForAdmin from './componets/adminNwClassConfirmed';
import ResetReq from './componets/ResetReq';
import Reset from './componets/Reset';

// 他のコンポーネントのインポート省略...
const API_URL =  'http://34.85.111.157:8080'; 
const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null); // 初期値をnullに
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await fetch(`${API_URL}/api/login/auth/check`, {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setIsAuthenticated(true);
          setIsAdmin(data.isAdmin || false);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('認証チェックエラー:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  if (isAuthenticated === null) {
    // 認証確認中はローディング状態を表示
    return <div>Loading...</div>;
  }

  const ProtectedRoute = ({ element, adminOnly = false }: { element: JSX.Element; adminOnly?: boolean }) => {
    if (adminOnly && !isAdmin) return <Navigate to="/" />;
    return isAuthenticated ? element : <Navigate to="/" />;
  };


  return (
    <Router>
      <Routes>
        {/* ログインページ */}
        <Route 
          path="/" 
          element={<LoginPage setIsAuthenticated={setIsAuthenticated} setIsAdmin={setIsAdmin} />} 
        />

        {/* 講師登録ページ（ログイン不要） */}
        <Route path="/teacher-registration" element={<TeacherRegistrationForm />} />
        <Route path="/apply" element={<StudentApplication />} />
        <Route path="/admin-test" element={<AdminPouse />} />
        <Route path="/replacement-request" element={<ReplacementRequest />} />
        <Route path="/reset-req" element={<ResetReq />} />
        <Route path="/reset-password/:token" element={<Reset />} />

        {/* 認証が必要なページ */}
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/teacher" element={<ProtectedRoute element={<TeacherMainPage />} />} />
        <Route path="/schedule/all" element={<ProtectedRoute element={<ScheduleAll />} />} />
        <Route path="/rewards" element={<ProtectedRoute element={<Rewards />} />} />
        <Route path="/schedule/teacher" element={<ProtectedRoute element={<ScheduleTeacher />} />} />
        <Route path="/replacement-classes" element={<ProtectedRoute element={<ReplacementClasses />} />} />
        <Route path="/newClassRecruitment" element={<ProtectedRoute element={<NewClassRecruitment />} />} />
        <Route path="/problem-selection" element={<ProtectedRoute element={<ProblemSelection />} />} />
        <Route path="/feedback-report" element={<ProtectedRoute element={<FeedbackReport />} />} />
        
        {/* 運営ページ */}
        <Route path="/admin" element={<ProtectedRoute element={<AdminPage />} />} />
        <Route path="/adminNewClass" element={<ProtectedRoute element={<AdminNewClass />}  />} />
        <Route path="/adminWithdraw" element={<ProtectedRoute element={<AdminWithdraw />}  />} />
        <Route path="/adminPause" element={<ProtectedRoute element={<AdminPouse />} />} />
        <Route path="/adminClass" element={<ProtectedRoute element={<AdminNewClassForAdmin />}  />} />
      </Routes>
    </Router>
  );
};

export default App;