import React, { useState } from 'react';
import axios from 'axios';
import './StudentApplicationPage.css'; // Assuming you create this CSS file for custom styling

interface FormData {
  firstName: string;
  lastName: string;
  furigana: string;
  birthDate: string;
  address: string;
  phoneNumber: string;
  userID: string;
  preferredSchedule: {
    firstPreferredDayOfWeek: string;
    firstPreferredTime: string;
    firstPrefferredTimeEnd: string;
    secondPreferredDayOfWeek?: string;
    secondPreferredTime?: string;
    thirdPreferredDayOfWeek?: string;
    thirdPreferredTime?: string;
  };
  howDidYouHear: string;
  participatedInLecture: boolean;
  universityPreference: string;
  wantsEntranceExamCourse: string;
}

const StudentApplicationPage: React.FC = () => {
  const [step, setStep] = useState(1);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    furigana: '',
    birthDate: '',
    address: '',
    phoneNumber: '',
    userID: '',
    preferredSchedule: {
      firstPreferredDayOfWeek: '',
      firstPreferredTime: '',
      firstPrefferredTimeEnd: '',
    },
    howDidYouHear: '',
    participatedInLecture: false,
    universityPreference: '',
    wantsEntranceExamCourse: '',
  });
  const [warning, setWarning] = useState<string | null>(null);

  const handleNextStep = () => {
    if (step === 1 && !acceptedTerms) {
      setWarning('Please accept the terms and conditions before proceeding.');
      return;
    }
    setWarning(null);
    if (step < 6) setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    if (step > 1) setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      const { checked } = e.target as HTMLInputElement;
      if (name === 'acceptedTerms') {
        setAcceptedTerms(checked);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    try {
        console.log(formData);
      await axios.post('/api/students', formData);
      alert('Form saved successfully');
    } catch (error) {
      console.error('Error saving form:', error);
      alert('Failed to save the form');
    }
  };

  return (
    <div className="application-form">
      <h1>Student Application Form</h1>
      <div className="form-container">
        {step === 1 && (
          <div className="form-step">
            <h2>Step 1: Terms and Conditions</h2>
            <div className="terms-container">
              <h2>第一章 総則</h2>
              <h3>【第１条 規約の適用】</h3>
              <p>（１）本規約は、 株式会社Overfocus（以下、「当社」といいます）が 提供するサービス「ヨミサマ。」（以下、「本サービス」といいます）についての利用契約の内容として、適用します。</p>
              <p>（２）本サービスの利用者（以下、「プログラム参加者」といいます）が未成年もしくは学生の場合、 当社との契約者（以下、「お客様」といいます）は、プログラム参加者の保護者（以下、「保護者様」といいます）といたします。</p>
              <p>（３）お客様は、プログラムお申込書を当社に提出した時点で、本規約について承諾したものとみなします。</p>

              <h3>【第２条 規約の変更】</h3>
              <p>当社は本規約を変更することがあります。なお、変更後の規約についてはウェブサイト上に掲載して、お知らせいたします。</p>

              <h3>【第３条 用語の定義】</h3>
              <ul>
                <li>プログラム参加者・・・・・本サービスの利用者として当社に登録された方</li>
                <li>保護者様・・・未成年もしくは学生であるプログラム参加者の保護者にあたる方</li>
                <li>お客様・・・・支払能力を持ち、当社と利用契約を結ぶ方</li>
                <li>講師・・・当社の講師（プログラム参加者への指導を担当）</li>
              </ul>

              <h2>第二章 契約</h2>
              <h3>【第４条 契約の成立】</h3>
              <p>（１）お客様がプログラムお申込書に署名した時点で契約成立とします。また、本規約の内容を理解し、同意したものとします。</p>
              <p>（２）以下の場合について、当社はお客様との契約をお断りする場合があります。</p>
              <ul>
                <li>① お客様が、申込時において、虚偽の事項を申告した場合</li>
                <li>② お客様による規約違反・契約違反を理由として、過去に当社から契約を解除されたことがある場合</li>
                <li>③ お客様の申込時におけるヒアリングの結果、プログラム参加者の時間的余裕がない又はプログラム参加者若しくは保護者様の本サービスへの協力姿勢がない等の理由により、本サービスの提供が困難であると当社が判断した場合</li>
                <li>④ プログラム参加者又は保護者様が第２３条１項の反社会的勢力に該当すると当社が判断した場合</li>
                <li>⑤ その他、相当の事由があると当社が判断した場合</li>
              </ul>
              <p>（３）契約の解除（解約）については、第２０条 および第２１条 に示すものとします。</p>

              <h3>【第５条 入会金】</h3>
              <p>入会の際、入会金として18,000円（税抜）を申し受けます。ただし、初回体験授業実施日から起算して翌日の１９時までにお申し込みいただいた場合、入会金は税込値段より5000円割引となります。</p>

              <h3>【第６条 本サービスの利用期間】</h3>
              <p>本サービスの利用期間は、原則として、成約日から、プログラム期間終了時までとします。 なお、解約については第２０条 、強制解約については第２１条 に示すものとします。</p>

              <h3>【第７条 登録情報の変更】</h3>
              <p>当社に登録されたお客様の住所や電話番号などの登録情報について、変更が生じた場合、当社へすみやかにご連絡ください。</p>

              <h2>第三章 サービス</h2>
              {/* ...other sections omitted for brevity... */}
            </div>
            <div className="checkbox-label">
              <input type="checkbox" name="acceptedTerms" onChange={handleChange} />
              <label>I agree to the terms and conditions</label>
            </div>
            {warning && <div className="warning">{warning}</div>}
          </div>
        )}

        {step === 2 && (
          <div className="form-step">
            <h2>Step 2: Personal Information</h2>
            <label>First Name: <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} /></label>
            <label>Last Name: <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} /></label>
            <label>Furigana: <input type="text" name="furigana" value={formData.furigana} onChange={handleChange} /></label>
            <label>Birth Date: <input type="date" name="birthDate" value={formData.birthDate} onChange={handleChange} /></label>
            <label>Address: <input type="text" name="address" value={formData.address} onChange={handleChange} /></label>
            <label>Phone Number: <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} /></label>
          </div>
        )}

        {step === 3 && (
          <div className="form-step">
            <h2>Step 3: Preferred Schedule</h2>
            <label>First Preferred Day: 
              <select name="firstPreferredDayOfWeek" value={formData.preferredSchedule.firstPreferredDayOfWeek} onChange={handleChange}>
                <option value="">Select Day</option>
                <option value="日">Sunday</option>
                <option value="月">Monday</option>
                {/* Add other days */}
              </select>
            </label>
            <label>First Preferred Start Time: 
              <input type="time" name="firstPreferredTime" value={formData.preferredSchedule.firstPreferredTime} onChange={handleChange} />
            </label>
            <label>First Preferred End Time: 
              <input type="time" name="firstPrefferredTimeEnd" value={formData.preferredSchedule.firstPrefferredTimeEnd} onChange={handleChange} />
            </label>
          </div>
        )}

        {step === 4 && (
          <div className="form-step">
            <h2>Step 4: University Preference</h2>
            <label>
              <select name="universityPreference" value={formData.universityPreference} onChange={handleChange}>
                <option value="">Select</option>
                <option value="Tokyo">Tokyo</option>
                <option value="Kyoto">Kyoto</option>
                {/* Add other options */}
              </select>
            </label>
            <label>
              <select name="wantsExamPreparationCourse" value={formData.wantsEntranceExamCourse} onChange={handleChange}>
                <option value="">Select</option>
                <option value="入試対策コースA">入試対策コースA</option>
                <option value="入試対策コースB">入試対策コースB</option>
                <option value="なし">希望しない</option>
              </select>
            </label>
          </div>
        )}

        {step === 5 && (
          <div className="form-step">
            <h2>Step 5: How Did You Hear About Us?</h2>
            <label>
              <select name="howDidYouHear" value={formData.howDidYouHear} onChange={handleChange}>
                <option value="">Select</option>
                <option value="Friends">Friends</option>
                <option value="Online">Online</option>
                {/* Add other options */}
              </select>
            </label>
          </div>
        )}
        <div className="form-navigation">
          <button onClick={handlePrevStep} disabled={step === 1}>Back</button>
          {step < 5 ? (
            <button onClick={handleNextStep}>Next</button>
          ) : (
            <button onClick={handleSave}>Submit</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentApplicationPage;