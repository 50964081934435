import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSchedules } from '../services/api';
import '../style/ScheduleTable.css';
import '../style/main.css'

interface ScheduleRow {
  originLessonDate: string;
  date: string;
  startTime: string;
  entryTime: string;
  endTime: string;
  studentId: number;
  slotId: number;
  teacherId: number;
  teacherName: string;
  studentName: string;
  comment: string;
  problem: string;
  material: string;
}

const ScheduleTable: React.FC = () => {
  const navigate = useNavigate();
  const [scheduleData, setScheduleData] = useState<ScheduleRow[]>([]);
  const [selectedRow, setSelectedRow] = useState<ScheduleRow | null>(null);
  const [selectedProblem, setSelectedProblem] = useState<string | null>(null);

  useEffect(() => {
    const getSchedules = async () => {
      try {
        const data = await fetchSchedules();
        
        const teacherId = Number(localStorage.getItem('userId'));
        const filteredData = data.filter((row: ScheduleRow) => row.teacherId === teacherId);
        
        setScheduleData(filteredData);
      } catch (error) {
        console.error('Failed to fetch schedules:', error);
      }
    };
    getSchedules();
  }, []);

  const handleRowClick = (row: ScheduleRow) => {
    setSelectedRow(row);
  };

  const handleProblemClick = (problemText: string) => {
    setSelectedProblem(problemText);
  };

  const handleOptionClick = (option: 'problem' | 'replacement' | 'report') => {
    if (selectedRow) {
      const routeMap = {
        problem: '/problem-selection',
        replacement: '/replacement-request',
        report: '/feedback-report',
      };
      navigate(routeMap[option], { state: { row: selectedRow } });
      setSelectedRow(null);
    }
  };

  const handlePopupClose = () => {
    setSelectedRow(null);
    setSelectedProblem(null);
  };

  // 日付を YYYY/MM/DD の形式で表示
  const formatDate = (dateString: string) => {
    return dateString.slice(0, 10).replace(/-/g, '/');
  };

  // 年月ごとにスケジュールをグループ化
  const groupByMonth = (data: ScheduleRow[]) => {
    return data.reduce((acc, row) => {
      const month = row.date.slice(0, 7); // YYYY-MMの部分だけ取得
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(row);
      return acc;
    }, {} as { [month: string]: ScheduleRow[] });
  };

  const groupedData = groupByMonth(scheduleData);

  return (
    <div className="schedule-table-container-schedule">
      <button className="back-button" onClick={() => navigate('/teacher')}>
        戻る
      </button>
      <h2 className="schedule-table-header-schedule">授業予定</h2>
      
      {Object.keys(groupedData).sort().map((month) => (
        <div key={month} className="schedule-month-group">
          <h3 className="schedule-month-header">{month.replace('-', '/')}月</h3>
          <table className="schedule-table-schedule">
            <thead>
              <tr>
                <th>ID</th>
                <th>日付</th>
                <th>開始時間</th>
                <th>講師入室時間</th>
                <th>終了時間</th>
                <th>生徒名</th>
                <th>講師名</th>
                <th>教材</th>
                <th>コメント</th>
                <th>問題</th>
              </tr>
            </thead>
            <tbody>
              {groupedData[month]
                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime() || a.startTime.localeCompare(b.startTime))
                .map((row, index) => (
                  <tr key={index} onClick={() => handleRowClick(row)}>
                    <td>{row.studentId}</td>
                    <td>{formatDate(row.date)}</td>
                    <td>{row.startTime}</td>
                    <td>{row.entryTime}</td>
                    <td>{row.endTime}</td>
                    <td>{row.studentName}</td>
                    <td>{row.teacherName}</td>
                    <td>{row.material}</td>
                    <td>{row.comment}</td>
                    <td onClick={(e) => {
                      e.stopPropagation();
                      handleProblemClick(row.problem);
                    }}>
                      {row.problem ? `${row.problem.slice(0, 10)}...` : '未選択'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ))}

      {/* 選択肢ポップアップ */}
      {selectedRow && (
        <div className="schedule-popup-schedule">
          <h3 className="schedule-popup-header-schedule">選択肢を選んでください:</h3>
          <p><strong>生徒名:</strong> {selectedRow.studentName}</p>
          <p><strong>講師名:</strong> {selectedRow.teacherName}</p>
          <button className="schedule-popup-button-schedule" onClick={() => handleOptionClick('problem')}>
            問題提示
          </button>
          <button className="schedule-popup-button-schedule" onClick={() => handleOptionClick('replacement')}>
            振替申請
          </button>
          <button className="schedule-popup-button-schedule" onClick={() => handleOptionClick('report')}>
            振り返りレポート
          </button>
          <button className="schedule-popup-button-close-schedule" onClick={handlePopupClose}>
            閉じる
          </button>
        </div>
      )}

      {/* 問題ポップアップ */}
      {selectedProblem && (
        <div className="problem-popup-schedule">
          <h3 className="problem-popup-header-schedule">問題の詳細</h3>
          <p>{selectedProblem}</p>
          <button className="problem-popup-button-close-schedule" onClick={handlePopupClose}>
            閉じる
          </button>
        </div>
      )}
    </div>
  );
};

export default ScheduleTable;